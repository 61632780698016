<template>
  <div class="insertion-sort">
    <h1 class="title">Move {{stepNo}} of {{steps.length-1}}</h1>
    <input type="text" v-model="input"/>
    <!-- eslint-disable-next-line -->
    <Visual v-if="isReady" :step = 'steps[stepNo]' :stepNo="stepNo" :isPlaying = "isPlaying" @first="stepNo=0" @prev="stepNo-=1" @play="togglePlay()" @next="stepNo+=1" @last="stepNo=steps.length-1" :sortedNos="sortedNos" />
  </div>
</template>

<script>
import Visual from './Visual.vue';

let playInterval = null;
export default {
  name: 'InsertionSort',
  components: {
    Visual,
  },
  data() {
    return {
      input: '83, 6, 63, 84, 9, 14, 90, 24, 17',
      steps: [],
      stepNo: -1,
      sortedNos: [],
      isReady: false,
      isPlaying: false,
    };
  },
  computed: {
    nos() {
      // eslint-disable-next-line no-self-compare
      return this.input.split(',').map((item) => parseInt(item.trim(), 10)).filter((number) => number === number);
    },
  },
  created() {
    this.computeSteps(this.nos);
    console.log(this.sortedNos);
  },
  methods: {
    togglePlay() {
      this.isPlaying = !this.isPlaying;
      if (!this.isPlaying) {
        clearInterval(playInterval);
        return;
      }
      playInterval = setInterval(() => {
        this.stepNo += 1;
        if (this.stepNo >= this.steps.length - 1) {
          clearInterval(playInterval);
        }
      }, 500);
    },
    insertionSort(nos) {
      this.steps.push({ msg: 'Highlighted green records on the left are sorted', sortedUptoIndex: 0, nos: this.nos });
      const sortedNos = [...nos];
      for (let i = 1; i < sortedNos.length; i += 1) {
        let currentRecord = i;
        this.steps.push({
          msg: 'Move the red record until it reaches its correct position', sortedUptoIndex: i - 1, currentRecord, nos: [...sortedNos],
        });
        for (let j = i; j > 0; j -= 1) {
          if (sortedNos[j - 1] > sortedNos[j]) {
            currentRecord -= 1;
            const temp = sortedNos[j - 1];
            sortedNos[j - 1] = sortedNos[j];
            sortedNos[j] = temp;
            this.steps.push({
              msg: 'Swap!', sortedUptoIndex: i, currentRecord, nos: [...sortedNos],
            });
          }
        }
      }
      this.steps.push({ msg: 'The list is now sorted!', nos: sortedNos, sortedUptoIndex: sortedNos.length });
      return sortedNos;
    },
    computeSteps(nos) {
      this.isReady = false;
      this.steps = [{ msg: 'Insertion sort visualization', nos: this.nos }];
      this.sortedNos = this.insertionSort(nos);
      this.stepNo = 0;
      this.isReady = true;
    },
  },
  watch: {
    nos(newValue) {
      this.computeSteps(newValue);
      console.log(this.sortedNos);
    },
    stepNo(newValue) {
      if (newValue < 1) {
        this.stepNo = 0;
      } else if (newValue > this.steps.length - 1) {
        this.stepNo = this.steps.length - 1;
      }
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.insertion-sort {
  font-size: 16px;

  h1 {
    font-weight: normal;
    font-size: 32px;
    line-height: 44px;
  }

  input {
    width: 392px;
    height: 36px;
    font-size: 16px;
    padding-left: 0.5rem;
    margin-top: 0.25rem;
  }
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

</style>
