<!-- eslint-disable max-len -->
<template>
  <div class="visual" v-if="stepNo !== -1">
    <p class="msg">{{step.msg}}</p>
    <ul class="bars">
      <li v-for="(no, index) in sortedNos" :key="index" class="bar" :style="getBarStyle(no)">
      </li>
    </ul>
    <div class="controls">
      <button class="button" @click="$emit('first')"><i class="fas fa-angle-double-left"></i></button>
      <button class="button" @click="$emit('prev')"><i class="fas fa-angle-left"></i></button>
      <button class="button" @click="$emit('play')"><i :class="['fas', !isPlaying ? 'fa-play' : 'fa-pause']"></i></button>
      <button class="button" @click="$emit('next')"><i class="fas fa-angle-right"></i></button>
      <button class="button" @click="$emit('last')"><i class="fas fa-angle-double-right"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      default: () => {},
    },
    stepNo: {
      type: Number,
      default: -1,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    sortedNos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getIndexOf(no, nos) {
      return nos.indexOf(no);
    },
    getBarBgColor(no) {
      if (no === this.step.currentRecord) {
        return 'hsla(330, 100%, 50%, 1)';
      }
      if (no <= this.step.sortedUptoIndex) {
        return 'hsla(160, 100%, 50%, 1)';
      }
      return 'white';
    },
    getBarStyle(no) {
      if (!this.step || !this.step.nos) {
        return '';
      }
      return `background: ${this.getBarBgColor(this.getIndexOf(no, this.step.nos))} ;height: ${(no + 1)}px; left:${16 + this.getIndexOf(no, this.step.nos) * 66}px;`;
    },
    printer() {
      console.log(this.step.pivot, this.step.left, this.step.right);
    },
  },
};
</script>

<style lang="scss">
.visual {
  background: black;
  height: 400px;
  width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem !important;
  color: white;

  .msg {
    margin: 0.5rem;
  }

  .bars {
    flex: 1;
    display: flex;
    align-items: flex-end;
    /* margin-left: 1rem; */
    position: relative;

    .bar {
      position: absolute;
      width: 50px;
      margin-right: 1rem;
      transition: all 1s ease;
      background: white;
    }
  }

  .controls {
    flex: 0;
    display: flex;
    justify-content: center;
    margin-top: 0.25rem;
    .button {
      border: none;
      width: 2rem;
      height: 2rem;
      background: hsl(270, 100, 50);
      border-radius: 3px;
      margin-left: 0.25rem;
      i {
        color: white;
      }
      &:active {
        opacity: 0.75;
      }
    }
  }
}

</style>
