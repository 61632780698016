<template>
  <div id="app">
    <InsertionSort msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import InsertionSort from './components/InsertionSort.vue';

export default {
  name: 'App',
  components: {
    InsertionSort,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  list-style: none;
}
#app {
  font-family: 'Open sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  margin: 1rem;
  /* align-items: center; */
  /* justify-content: center; */
}
</style>
